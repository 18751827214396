module.exports = {
  aboutUs: {
    title: "关于我们",
    requiredParams: {},
    optionalParams: {}
  },
  loveToBe: {
    title: "臻爱倍至",
    requiredParams: {},
    optionalParams: {}
  },
  smallWarm: {
    title: "暖小加",
    requiredParams: {},
    optionalParams: {}
  },
  nusrseLink: {
    title: "护士加",
    requiredParams: {},
    optionalParams: {}
  },
  index: {
    title: "首页",
    requiredParams: {},
    optionalParams: {}
  }
};

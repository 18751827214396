<template>
  <div class="footer">
    <div class="msg">
      <div class="content">
        <div class="logo"><img src="./img/logo.png" alt="" /></div>
        <div class="list">
          <div>
            <div class="title">产品</div>
            <p><a href="./nusrseLink.html"> 护士加</a></p>
            <p><a href="./smallWarm.html"> 暖小加</a></p>
            <p><a href="./loveToBe.html"> 臻爱倍至</a></p>
          </div>
          <div style="margin-left:53px">
            <div class="title">公司</div>
            <p><a href="./aboutUs.html"> 关于我们</a></p>
          </div>
        </div>
        <div class="list">
          <div>
            <div class="title">联系我们</div>
            <div class="message">
              <p><label>联系电话：</label><span>400-8650-512</span></p>
              <p><label>人才招聘：</label><span>hr@hulian120.com</span></p>
            </div>
          </div>
        </div>
        <div class="list">
          <div>
            <div class="title">关注我们</div>
            <p><img src="./img/ercode.png" alt="" /></p>
            <p class="wechat">护联网 <br />官方公众号</p>
          </div>
        </div>
      </div>
    </div>
    <div class="bottom">
      <p>
        Copyright © 2015 - {{ new Date().getFullYear() }} hulian120.com. All
        Rights Reserved 北京健康护航科技有限公司版权所有
      </p>
      <p>
        <a href="https://beian.miit.gov.cn/">京ICP备14052782号-1</a>
        京公网安备
        <a
          href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010802029798"
          >11010802029798</a
        >
      </p>
    </div>
  </div>
</template>
<script>
export default {};
</script>
<style lang="scss" scoped>
@import "@/assets/style/var.scss";
.footer {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: rgba(51, 51, 51, 1);
  .msg {
    width: $--container-width;
    height: 244px;
    display: flex;
    justify-content: center;
    align-items: center;
    .logo {
      margin-right: 58px;
      display: flex;
      align-items: flex-start;
      img {
        width: 112px;
        height: auto;
      }
    }
    .content {
      display: flex;
    }
    .list {
      border-right: 1px solid #434343;
      display: flex;
      padding: 0 44px;
      &:last-child {
        border: none;
      }
      .title {
        font-size: 14px;
        font-weight: bold;
        color: rgba(255, 255, 255, 1);
        &:after {
          content: "";
          display: block;
          width: 23px;
          height: 2px;
          background: rgba(65, 65, 65, 1);
          border-radius: 1px;
          margin-top: 11px;
        }
      }
      p {
        margin: 0;
        padding: 0;
        font-size: 12px;
        margin-top: 14px;
        display: flex;
        justify-content: space-between;
        color: rgba(178, 178, 178, 1);
        cursor: pointer;
        a {
          text-decoration: none;
          color: rgba(178, 178, 178, 1);
        }
        img {
          width: 72px;
          height: 72px;
          display: flex;
          flex-shrink: 0;
        }
      }
      .wechat {
        line-height: 1.3;
      }
      .message {
        width: 211px;
        p {
          width: 100%;
          display: flex;
          justify-content: space-between;
          span {
            display: inline-block;
            flex: 1;
            text-align: right;
          }
        }
      }
    }
  }
  .bottom {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 58px;
    background: rgba(41, 41, 41, 1);
    font-size: 12px;
    color: rgba(178, 178, 178, 1);
    a {
      color: rgba(178, 178, 178, 1);
    }
    p {
      margin: 0;
      padding: 0;
      line-height: 20px;
    }
  }
}
</style>

const devApiUrl = "https://apidev.yjzsedu.com"; //本地开发环境
const buildDevApiUrl = "http://101.201.100.210:8078"; //打包开发环境
const buildTestApiUrl = "http://192.168.48.192:9188"; //打包测试环境
const buildPreApiUrl = "https://apidev.yjzsedu.com"; //打包预发布环境
const buildProApiUrl = "https://api.yjzsedu.com"; //打包正式环境

let useApiUrl;

if (process.env.NODE_ENV === "development") {
  useApiUrl = devApiUrl;
} else {
  //production
  switch (process.env.VUE_APP_MODE) {
    case "buildDev":
      useApiUrl = buildDevApiUrl;
      break;
    case "buildTest":
      useApiUrl = buildTestApiUrl;
      break;
    case "pre":
      useApiUrl = buildPreApiUrl;
      break;
    default:
      useApiUrl = buildProApiUrl;
      break;
  }
}

export { useApiUrl }; //可以导出更多需要不同环境区分的url

<template>
  <div class="header">
    <div class="head">
      <img src="./img/logo.png" alt="" @click="returnHome" />
      <div class="menu">
        <div
          v-for="item in menuList"
          :key="item.id"
          :class="active === item.id ? 'active' : ''"
        >
          <a :href="'./' + item.id + '.html'"> {{ item.name }}</a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    active: {
      type: String,
      default: "index"
    }
  },
  data() {
    return {
      menuList: [
        {
          name: "首页",
          id: "index"
        },
        {
          name: "护士加",
          id: "nusrseLink"
        },
        {
          name: "暖小加",
          id: "smallWarm"
        },
        {
          name: "臻爱倍至",
          id: "loveToBe"
        },
        {
          name: "关于我们",
          id: "aboutUs"
        }
      ]
    };
  },
  methods: {
    returnHome() {
      window.location.href = "./index.html";
    }
  }
};
</script>
<style lang="scss" scoped>
@import "@/assets/style/var.scss";
.header {
  width: 100%;
  display: flex;
  justify-content: center;
  height: 80px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 4px 9px 0px rgba(0, 0, 0, 0.06);
  position: sticky;
  top: 0;
  left: 0;
  z-index: 100;
  .head {
    width: $--container-width;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  img {
    top: 4px;
    width: 142px;
    position: relative;
    height: auto;
    z-index: 10;
  }
  .menu {
    display: flex;
    div {
      margin-right: 39px;
      font-size: 18px;
      text-align: center;
      position: relative;
      a {
        color: rgba(34, 34, 34, 1);
        text-decoration: none;
      }
      &:last-child {
        margin-right: 0;
      }
      &.active,
      &:hover {
        &:after {
          content: "";
          display: block;
          width: 22px;
          height: 3px;
          background: rgba(248, 64, 37, 1);
          border-radius: 6px;
          position: absolute;
          left: 50%;
          margin-left: -11px;
          bottom: -10px;
        }
      }
    }
  }
}
</style>
